
import Vue from 'vue';
import { CommonItem } from '../../../../types/ads';
export default Vue.extend({
  name: 'commonConnectionItem',
  props: ['item', 'connection'],
  methods: {
    toggleItem(item: CommonItem): void {
      item.status = item.status === 'checked' ? 'unchecked' : 'checked';
      this.$forceUpdate(); // Force component update
      this.$emit('toggleItem', item, this.connection.accountName);
    },
  },
});
